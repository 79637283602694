"use strict";
exports.__esModule = true;
/**
 * 弹窗事件
 */
var confirm = /** @class */ (function () {
    function name(option) {
        var defaultOption = {
            title: '确认删除',
            content: '确认删除选择的数据？',
            mask: true,
            height: '120',
            width: '230',
            confirm: null,
            cancel: null
        };
        this.option = $.extend({}, defaultOption, option);
        this.init();
    }
    name.prototype.init = function () {
        var _self = this;
        this.winHeight = $(window).outerHeight();
        this.windWidth = $(window).outerWidth();
        var html = '<div class="comfirm_model">';
        if (this.option.mask) {
            html += '<div class="comfirm_mask" style="width:100%;height:100%;background:#000;opacity:0.5;"></div>';
        }
        html += '<div class="comfirm_container">' +
            '<div class="comfirm_header"><div class="comfirm_tit">' + this.option.title + '</div><div class="confirm_more"><i class="icon icon_confirm_close icon_close"></i></div></div>' +
            '<div class="confirm_content">' + this.option.content + '</div>' +
            '<div class="confirm_foot">';
        if (this.option.confirmText) {
            html += '<button class=" btn-confirm">' + this.option.confirmText + '</botton>';
        }
        if (this.option.cancelText) {
            html += '<button class=" btn-cancel">' + this.option.cancelText + '</botton></div>';
        }
        html += '</div></div></div>';
        this.html = $(html);
        $('body').append(this.html);
        this.html.css({
            "position": "fixed",
            "top": "0",
            "left": "0",
            "height": this.winHeight + 'px',
            "width": this.windWidth + 'px',
            "z-index": "9999"
        });
        $('.comfirm_container', this.html).css({
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "height": _self.option.height + 'px',
            "width": _self.option.width + 'px',
            "margin-top": '-' + _self.option.height / 2 + 'px',
            "margin-left": '-' + _self.option.width / 2 + 'px'
        });
        this.html.on('click', '.icon_close', function () {
            _self.remove();
        });
        this.html.on('click', '.btn-confirm', function () {
            if (typeof _self.option.confirm === 'function') {
                _self.option.confirm(_self);
            }
        });
        this.html.on('click', '.btn-cancel', function () {
            if (typeof _self.option.cancel === 'function') {
                _self.option.cancel(_self);
            }
        });
    };
    ;
    name.prototype.remove = function () {
        if (this.html) {
            this.html.remove();
        }
    };
    return name;
}());
exports["default"] = confirm;
