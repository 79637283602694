/**
 * 全站用历史记录前端方法
 */
(function ($) {
    //if (typeof (qjdata) == 'undefined')
    //    return;
    function isInArray(str, arr) {
        var res = -1;
        for (var i = 0; i < arr.length; i++) {
            var name = arr[i].split("|")[0];
            if (str == name) {
                res = i;
            }
        }
        return res;
    }
    if (typeof window.localStorage == undefined)
        return;
    var records = localStorage.getItem('dRecords');
    if (!!records && $('#hisWrap').length) {
        var data = records.split(',*');
        var str = "";
        if (data.length > 10) {
            str = "style='display:block;'";
        }
        var html = ['<div class="histxt">您最近访问数据：</div><div class="spread_more" ' + str + '>更多<i class="icon icon_history_more"></i><i class="icon icon_history_top"></i></div><div class="hisbox"><ul class="hisul">'];
        for (var i = data.length - 1; i >= 0; i--) {
            if (data[i] == '') {
                data.splice(i, 1); //删除空白元素
                continue;
            }
            var item = data[i].split('|');
            if (item[0] && item[1]) {
                html.push('<li><a href="' + item[1] + '" target="_blank" tracker-eventcode="sjpd_sjzxsy_sjzxggsj_djzjfw">' + item[0] + '</a></li>');
            }
        }
        html.push('</ul></div>');
        html = html.join('');
        $('#hisWrap').append(html);
        $('#hisWrap').show();
        var liWidth = 0;
        var hisWidth = $('#hisWrap').width() || 1000;
        $('#hisWrap .hisul>li').each(function () {
            liWidth += $(this).width() + 25;
        });
        if (liWidth > (hisWidth - 150)) {
            $('#hisWrap .spread_more').show();
        }
        $('#hisWrap .spread_more').click(function () {
            $('#hisWrap').toggleClass("active");
        });
    }
    if (typeof page_type == 'undefined' && typeof page_parent_type == 'undefined')
        return;
    records = !!records ? records.split(',*') : [];
    var name = "", href = "";
    if (typeof page_type != 'undefined' && page_type) {
        var $menu = $('#leftnav');
        var item_1 = $('span[data-key="' + page_type + '"]', $menu);
        if (item_1.length > 0) {
            item_1 = item_1.eq(0);
        }
        href = item_1.data("href") || '';
        name = item_1.data("name") || '';
    }
    if (typeof page_parent_type != 'undefined' && page_parent_type) {
        name = page_parent_type;
        href = location.href;
    }
    if (typeof page_detail_type != 'undefined' && page_detail_type) {
        href = location.href;
        name += '-' + page_detail_type;
    }
    if (href == '' || name == '' || name.length > 14) {
        return;
    }
    var val = name + "|" + href;
    var _index = isInArray(name, records);
    if (_index == -1) {
        if (records.length >= 20) {
            records.shift();
        }
    }
    else {
        records.splice(_index, 1);
    }
    records.push(val);
    localStorage.setItem("dRecords", records.join(",*"));
})(jQuery);
