"use strict";
/****
 * 拖拽公共组件
 *
 *  */
exports.__esModule = true;
var sortTable = /** @class */ (function () {
    function sortTable(opt) {
        this.eventNames = {
            start: "touchstart.sortable mousedown.sortable",
            drop: "touchend.sortable touchcancel.sortable mouseup.sortable",
            drag: "touchmove.sortable mousemove.sortable",
            scroll: "scroll.sortable"
        };
        var defaultOption = {
            el: '#sort',
            itemSelector: 'li',
            itemTarget: 'li',
            placeholderClass: 'placeholder',
            dragClass: 'dragged',
            bodyClass: 'dragging',
            delay: 1000,
            callback: null
        };
        this.option = $.extend({}, defaultOption, opt);
        this.handle = this.option.itemTarget;
        this.target = $(this.option.el);
        this.target.on(this.eventNames.start, this.handle, $.proxy(this.dragInit, this));
    }
    ;
    sortTable.prototype.dragInit = function (e) {
        document.body.onselectstart = document.body.ondrag = function () {
            return false;
        };
        if (this.seter) {
            window.clearTimeout(this.seter);
        }
        this.document = $(document);
        var closetItem = $(e.target).closest(this.option.itemSelector);
        if (closetItem.length) {
            this.item = closetItem;
            this.dragProxy = $.proxy(this.drag, this);
            this.dropProxy = $.proxy(this.drop, this);
            var placeholder = document.createElement(this.option.itemSelector);
            placeholder.className = this.option.placeholderClass;
            this.placeholder = $(placeholder);
            this.placeholder.css({
                height: this.item.outerHeight(),
                width: this.item.outerWidth()
            });
            this.setPointer(e);
            this.toggleListeners('on');
            this.dragInitDone = true;
        }
    };
    ;
    sortTable.prototype.drag = function (e) {
        var _self = this;
        if (!this.dragging) {
            this.item.css({
                height: this.item.outerHeight(),
                width: this.item.outerWidth(),
                position: 'absolute'
            });
            this.item.addClass(this.option.dragClass);
            $("body").addClass(this.option.bodyClass);
            this.item.before(this.placeholder);
            this.dragging = true;
        }
        this.setPointer(e);
        this.item.css(this.getRelativePosition(this.pointer, this.item.offsetParent()));
        // this.item.css({'display':'none'});
        var items = this.target.find(this.option.itemSelector).filter(":not(." + this.option.placeholderClass + ",." + this.option.dragClass + ")");
        var itemFirst = items.eq(0);
        if (itemFirst.length > 0) {
            var itemFirstY = itemFirst.offset().top + itemFirst.outerHeight();
        }
        if (_self.pointer.top < itemFirstY && itemFirst.length > 0) {
            itemFirst.before(_self.placeholder);
        }
        items.each(function () {
            // var $li: JQuery<HTMLElement> = $(this);
            var $li = $(this);
            var y = $li.offset().top;
            if (_self.pointer.top > y) {
                $li.after(_self.placeholder);
            }
        });
    };
    ;
    sortTable.prototype.drop = function (e) {
        var _self = this;
        this.toggleListeners('off');
        this.dragInitDone = false;
        if (this.dragging) {
            this.placeholder.before(this.item).detach();
            this.item.removeClass(this.option.dragClass).removeAttr("style");
            $("body").removeClass(this.option.bodyClass);
            this.dragging = false;
            this.seter = setTimeout(function () {
                _self.option.callback && _self.option.callback();
            }, _self.option.delay);
        }
        document.body.onselectstart = document.body.ondrag = function () {
            return true;
        };
    };
    ;
    sortTable.prototype.getPointer = function (e) {
        var o = e.originalEvent || e.originalEvent.touches && e.originalEvent.touches[0];
        return {
            left: e.pageX || o.pageX,
            top: e.pageY || o.pageY
        };
    };
    ;
    sortTable.prototype.setPointer = function (e) {
        var pointer = this.getPointer(e);
        this.lastPointer = this.pointer;
        this.pointer = pointer;
    };
    ;
    sortTable.prototype.getRelativePosition = function (pointer, element) {
        var offset = element.offset();
        return {
            left: pointer.left - offset.left,
            top: pointer.top - offset.top
        };
    };
    ;
    sortTable.prototype.toggleListeners = function (method) {
        var _self = this;
        var events = ['drag', 'drop'];
        $.each(events, function (i, event) {
            _self.document[method](_self.eventNames[event], _self[event + 'Proxy']);
        });
    };
    return sortTable;
}());
exports["default"] = sortTable;
