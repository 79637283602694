/****
 * 前端搜索组件
 *
 *  */
var StockCodeBar = new suggest2017({
    inputid: 'StockCode_bar',
    placeholder: '输代码、名称或简拼',
    shownewtips: true,
    onSubmit: 'search'
});
$(function () {
    $(".suggest2017_form").each(function () {
        var sform = $(this);
        var id = "bar_" + Math.ceil(Math.random() * 100000);
        $(":text", sform).attr("id", id);
        var left = $(this).data("left") || 0;
        var stype = $(this).data("stype") || "1,2,25";
        var link = $(this).data("link") || "/";
        var sugitem = new suggest2017({
            filter: {
                securitytype: stype
            },
            showblank: false,
            modules: ["stock"],
            offset: { left: left, top: 0 },
            width: 280,
            showstocklink: false,
            inputid: id,
            placeholder: '输代码、名称或简拼',
            onSubmit: function (result) {
                if (result.stock) {
                    var url = link.replace("{code}", result.stock.Code);
                    url = url.replace("{quoteid}", result.stock.QuoteID);
                    window.open(url);
                }
                else {
                    alert("请输入所查询股票的代码!");
                }
                return false;
            },
            onConfirmStock: function (result) {
                var url = link.replace("{code}", result.stock.Code);
                url = url.replace("{quoteid}", result.stock.QuoteID);
                window.open(url);
                return false;
            }
        });
    });
});
