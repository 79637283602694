/**
 * 全站用根据终端跳转到wap站方法
 */
;
(function ($) {
    var url = location.href;
    url = url.substring(url.indexOf(location.host));
    if (url.indexOf("iswww=www") != -1) {
        document.cookie = "iswww=www; path=/";
    }
    //判断是否从wap 页面跳过来的
    var isWWW = document.cookie.match(new RegExp("(^| )iswww=www(;|$)")) || document.cookie.match(new RegExp("(^| )has_jump_to_web=1(;|$)"));
    if (isWWW)
        return false;
    var ua = navigator.userAgent;
    var ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
    var isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/);
    var isAndroid = ua.match(/(Android)\s+([\d.]+)/) && ua.match(/(Mobile)\s+/);
    var isMobile = isIphone || isAndroid;
    if (isMobile) {
        var murl = $("meta[name=mobile-agent]").attr("content").replace('format=html5; url=', '');
        if (murl != "" && murl != 'https://wap.eastmoney.com')
            location.href = murl;
    }
})(jQuery);
